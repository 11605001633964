.ReviewCard-Div {
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    margin: 10px;
}

.ReviewCard-Image {
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
}

.ReviewCard-Info-Div {
    padding: 20px;
}

.ReviewCard-Review {
    font-size: 16px;
}

.ReviewCard-Star {
    color: #F13C20;
    font-size: 18px;
}