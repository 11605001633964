

.Banner-Header {
    height: 100vh;
    width: 100%;
    background: black;
    overflow: hidden;
}

.Banner-Video {
    object-fit: cover;
    opacity: 0.7;
    width: 100%;
    height: 100vh;
}

.Banner-Center {
    height: 100vh;
    width: 100%;
    position: relative;
}

.Banner-Navbar {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.Banner-Header-Text {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Banner-Title {
    color: white;
    font-size: 60px;
    letter-spacing: 4px;
    text-align: center;
    margin-bottom: 3px;
}

.Banner-Input {
    border-radius: 10px;
}

.Banner-Email-Submit {
    text-align: center;
}

.Banner-Input-SubText {
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: 600;
}