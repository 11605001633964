.Footer-Anchor-Link {
    font-size: 17px;
    color: black;
    font-weight: 400;
}

.Footer-Link-Div {
    padding-top: 4px;
    padding-bottom: 4px;
}

.Footer-Right-Col {
    text-align: right;
}

.Ace--Logo {
    margin-top: 10px;
    margin-bottom: 20px;
    max-width: 100px
}

@media only screen and (max-width: 600px) {
    .Footer-Button {
        margin-top: 20px;
    }
}