.Navbar-Anchor-Link {
    font-size: 18px;
    color: white;
    font-weight: 600;
}

.Navbar-Anchor-Link-Small {
    font-size: 18px;
    color: black;
    text-align: center;
}

.Navbar-Row {
    padding-top: 15px;
}

.Button-Red {
    color: white;
    background-color: #F13C20;
    border-radius: 10px;
    border-color: #F13C20;
}

.Button-Black {
    color: white;
    background-color: black;
    border-radius: 10px;
    border-color:black;
}

.Navbar-Social-Media {
    color: white;
}

.Navbar-Drawer-Div {
    text-align: center;
    max-width: 100%;
    padding: 5px;
}

.Navbar-Drawer-Col {
    text-align: center;
}

.Navbar-Drawer-Row {
    max-width: 350px;
}

.Drawer-Button {
    min-width: 200px;
}

.Navbar-Drawer {
    width: 350px;
}