.About-Image {
    width: 100%;
    max-height: 600px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    text-align: center;
}

.About-Text-Col {
    padding: 20px;
}

.About-Title {
    font-size: 30px;
    margin-bottom: 2px;
    font-weight: 700;
}

.About-Subtitle {
    font-size: 23px;
    padding-bottom: 20px;
    margin-top: 0px;
    font-weight: 600;
}

.About-Paragraph {
    font-size: 16px;
}