.Landing-Div-Space {
    padding-top: 50px;
    padding-bottom: 50px;
}

.Landing-Main-Div {
    width: 100vw;
    overflow-x: hidden;
}

.Landing-Main-Div-Spacing {
    padding-left: 40px;
    padding-right: 40px;
}

.Current-Offering {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.iframeSize {
    height: 300px;
    width: 100% !important;
    padding-left: 30px;
    padding-right: 30px;
}


@media only screen and (max-width: 600px) {
    .Landing-Main-Div-Spacing {
        padding-left: 5px;
        padding-right: 5px;
    }
}