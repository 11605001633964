.ServiceCard-Image {
    width: 100%;
    min-height: 250px;
    max-height: 400px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
}

.ServiceCard-Title {
    font-size: 25px;
}

.ServiceCard-SubTitle {
    font-size: 20px;
    color: red;
}

.ServiceCard-Description {
    font-size: 18px;
}

.ServiceCard-Row {
    margin: 10px;
}